body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading-font {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: silver;
  font-size: 1.3rem;
  font-weight: bolder;
  text-align: left;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
}

.details-font {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: white;
  font-size: 1.3rem;
  text-align: left;
  padding-left: 20px;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
}

.status-font {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: white;
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: left;
}